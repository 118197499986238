<template>
  <section v-if="isloading == false">
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Turmas</h2>
      </div>
      <div class="col-md-5 tbl-cadastro" v-if="acesso.cadastrar">
        <pm-Button
          v-if="situacaoAno"
          type="button"
          @click="registar()"
          label="CADASTRAR NOVA TURMA"
          class="p-button-success"
          icon="pi pi-external-link"
          title="CADASTRAR NOVA TURMA"
        ></pm-Button>

        <pm-Button
          v-if = "this.funcao != 1 && this.funcao != 2 && this.funcao != 7"
          type="button"
          style="margin-right:5px;"
          @click="verificarNota()"
          label="VERIFICAR NOTAS DAS TURMAS"
          icon="pi pi-external-link"
          title="VERIFICAR NOTAS DAS TURMAS"
          class="p-button-Wanning"
        ></pm-Button>
      </div>
    </div>

    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable
        :value="turmasALL"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
      >
        <template #header>
          <div class="p-fluid formgrid grid" style="font-size: 12px">
            <div class="field col-12 md:col-4 lg:col-3" style="margin: 0px">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <pm-InputText
                  v-model="pesquisa"
                  @keyup.enter.space="pesquisar"
                  placeholder="Pesquisar Nome da Turma"
                  style="height: 34px !important;"
                />
              </span>
            </div>

            <div class="field col-12 md:col-4 lg:col-2" style="margin: 0px">
              <select
                class="p-inputtext p-component"
                v-model="escola_turm"
                style="appearance: revert !important; width: 100% !important; height: 34px !important;"
                @change="pesquisar(),buscarSegmentoALL()"
              >
                <option value="0" disabled selected>
                  -- Escolha a Escola --
                </option>
                <option v-for="n in escolaALL" :key="n" :value="n.id" >
                  {{ n.nome }}
                </option>
              </select>
            </div>
            <div class="field col-12 md:col-4 lg:col-2" style="margin: 0px">
              <select
                class="p-inputtext p-component"
                v-model="segmento_turm"
                @change="pesquisar()"
                style="appearance: revert !important; width: 100% !important; height: 34px !important;"
              >
                <option value="0" disabled selected>
                  -- Escolha o Segmento --
                </option>
                <option v-for="n in segmentoALL" :key="n" :value="n.id"  @change="pesquisa">
                  {{ n.nome }}
                </option>
              </select>
            </div>
            <div class="field col-12 md:col-4 lg:col-4" style="margin: 0px">
              <button
                style="height: 34px !important; font-size: 14px !important;"
                class="btn btn-secondary mr-2"
                @click="limparFiltro"
              >
                Limpar filtro
              </button>
              <button
                style="height: 34px !important; font-size: 14px !important;"
                class="btn btn-primary"
                @click="pesquisar()"
              >
                Pesquisar
              </button>
            </div>
          </div>
        </template>
        <pm-Column
          field="nome"
          sortable
          header="NOME DA TURMA"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="segmento"
          sortable
          header="SEGMENTO"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="serie"
          sortable
          header="PERÍODO"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="escola"
          sortable
          header="ESCOLA"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="turno"
          sortable
          header="TURNO"
          class="colunn-tbl"
        >
          <template #body="{ data }">
            <div v-if="data.turno == 'Noturno'" style="color: #2b22b5">
              <span class="badge badge-noturno"> {{ data.turno }}</span>
            </div>
            <div v-else-if="data.turno == 'Vespertino'" style="color: #efa029">
              <span class="badge badge-vespertino"> {{ data.turno }}</span>
            </div>
            <div v-else-if="data.turno == 'Matutino'" style="color: #28a0ff">
              <span class="badge badge-matutino"> {{ data.turno }}</span>
            </div>
            <div v-else-if="data.turno == 'Diurno'" style="color: #28a0ff">
              <span class="badge badge-matutino"> {{ data.turno }}</span>
            </div>
          </template>
        </pm-Column>
        <pm-Column
          field="qdt_alunos"
          header="QTDE DE ALUNOS"
          style="
            text-align: center;
          "
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field=""
          header="SITUAÇÃO"
          class="colunn-tbl"
        >
          <template #body="{ data }">
            <va-badge
              v-if="data.ativa == 0 && situacaoAno"
              class="table_style"
              :text="'INATIVA'"
              :color="'#f50000'"
              :disabled="desabilitarBotao"
            />
            <va-badge
              v-if="data.ativa == 1 && situacaoAno"
              class="table_style"
              :text="'ATIVA'"
              :color="'#09b163'"
            />
          </template>
        </pm-Column>
        <pm-Column
          field="info"
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: right; overflow: visible;/*width: 357px;*/vertical-align:middle !important;padding-right: 5px !important;"
          class="colunn-tbl"
        >
          <template #body="{ data }">
            <va-button-dropdown
              class="ml-2"
              color="#efefef"
              :rounded="false"
            >
              <template #label>
                <h5 style="font-size: 15px; margin-bottom: 0px">Ações</h5>
              </template>
              <div class="n_ger">
                <va-list>
                  <va-list-item
                    v-if="data.ativa == 0 && situacaoAno"
                    @click="start_turma(data.id)"
                    title="Ativar Turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-play p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label> Ativar Turma </va-list-item-label>

                      <!--<va-list-item-label caption>
                          caption
                        </va-list-item-label>-->
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    @click="visualizacaoTurma(data.estado.id)"
                    title="visualizar dados da turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-search-plus p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label>
                        Visualizar dados da turma
                      </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    v-if="data.ativa == 0 && acesso.editar && situacaoAno"
                    @click="editarTurma(data.id, 0)"
                    title="Editar Turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-user-edit p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label> Editar Turma </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    v-if="data.ativa == 1 && acesso.editar && situacaoAno"
                    @click="editarTurma(data.id, 1)"
                    title="Editar Turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-user-edit p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label> Editar Turma </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    v-if="
                      situacaoAno && acesso.cadastrar && data.segmento_tipo != 0
                    "
                    @click="addDisciplina(data)"
                    title="Adicionar Disciplinas na Turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-id-card p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label>
                        Adicionar Disciplinas na Turma
                      </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    v-if="situacaoAno && acesso.cadastrar"
                    @click="addDisciplinaEProfessor(data)"
                    title="Adicionar Professor na Turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-id-card p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label>
                        Adicionar Professor na Turma
                      </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    v-if="situacaoAno && acesso.cadastrar"
                    @click="addAlunosNaTurma(data)"
                    title="Adicionar Alunos na turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-user-plus p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label>
                        Adicionar Alunos na turma
                      </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    v-if="data.ativa == 0 && acesso.deletar && situacaoAno"
                    @click="deletarTurma(data.id)"
                    title="Remover Turma"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-user-plus p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label> Remover Turma </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <va-list-item
                    v-if="situacaoAno && acesso.cadastrar && data.segmento_tipo == 3 && data.semestre == 1"
                    @click="encerrarTurmaEja(data)"
                    title="Encerrar 1° semestre do EJA"
                    style="cursor: pointer"
                  >
                    <va-list-item-section icon>
                      <span class="pi pi-user-minus p-button-icon"></span>
                    </va-list-item-section>
                    <va-list-item-section>
                      <va-list-item-label>
                        Encerrar 1° semestre do EJA
                      </va-list-item-label>
                    </va-list-item-section>
                  </va-list-item>

                  <div
                    v-if="
                      this.funcao == 3 || this.funcao == 4 || this.funcao == 5
                    "
                  >
                    <!--v-if="data.segmento_tipo != 0"-->
                    <va-list-item

                      @click="verificarPresenca(data)"
                      title="Verificar as presenças da turma"
                      style="cursor: pointer"
                    >
                      <va-list-item-section icon>
                        <span class="pi pi-check-square p-button-icon"></span>
                      </va-list-item-section>
                      <va-list-item-section>
                        <va-list-item-label>
                          Verificar as presenças da turma
                        </va-list-item-label>
                      </va-list-item-section>
                    </va-list-item>

                    <!--<va-list-item
                      v-if="data.segmento_tipo != 0"
                      @click="verificarNota(data)"
                      title="Verificar as notas da turma"
                      style="cursor: pointer"
                    >
                      <va-list-item-section icon>
                        <span class="pi pi-file p-button-icon"></span>
                      </va-list-item-section>
                      <va-list-item-section>
                        <va-list-item-label>
                          Verificar as notas da turma
                        </va-list-item-label>
                      </va-list-item-section>
                    </va-list-item>-->
                  </div>
                </va-list>
              </div>
            </va-button-dropdown>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom">
        <div class="p-paginator-pages" style="display: inline-flex">
          <div v-for="n in links" :key="n">
            <button
              class="p-paginator-next p-paginator-element p-link"
              @click="paginas(n)"
              v-if="!n.active"
              style="border: 0px solid #ebebeb; border-radius: 0"
            >
              <p
                class="p-paginator-icon"
                v-html="n.label"
                style="margin-bottom: 0px !important"
              ></p>
            </button>
            <button
              class="p-paginator-page p-paginator-element p-link p-highlight"
              @click="paginas(n)"
              v-if="n.active"
              style="border-radius: 0"
            >
              {{ n.label }}
            </button>
          </div>
        </div>
        <selectPorPag :key="perPage" :qdt="perPage" @changePagina="changePagina"></selectPorPag>

      </div>
    </div>
  </section>
  <pm-Dialog header="Alerta de Ativação" v-model:visible="confime_startTurma" :style="{width: '50vw'}" :maximizable="true" :modal="true">
    <div class="field col-12" >
      <div class="row">
        Depois de ativar não é possivel desativar. Cuidado! Depois de ativa a partir desse momento Transferencia internas e externas serão realizadas na turma.
        O funcionamento de presenças e notas serão contabilizadas.
      </div>
    </div>
    <template #footer>
      <pm-Button
        type="button"
        @click="start_turma_Confimado()"
        icon="pi pi-play"
        label="Aprovar Ativação"
        title="Aprovar Ativação"
        class="p-button-sm p-button-success btn-color table_style"
        style="margin-right: 5px;float: left;"
      >
      </pm-Button>
      <pm-Button
        type="button"
        @click="confime_startTurma = false"
        icon="pi pi-close"
        label="Cancelar"
        title="Cancelar"
        class="p-button-sm p-button-danger btn-color table_style"
        style="margin-right: 5px;float: left;"
      >
      </pm-Button>
    </template>
  </pm-Dialog>
  <section v-if="isloading == true">
    <div>
      <va-progress-circle
        indeterminate
        :size="400"
        style="margin-left: auto; margin-right: auto"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Turma } from "@/class/turma.js";
import { Verificar } from "@/class/verificar.js";
import { Calendario } from "@/class/calendario.js";
import axios from "axios";
import { Escola } from "@/class/escolas.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import Util from "@/utils/rota";
import { LoginInfo } from "@/class/login";
import selectPorPag from "@/components/selectPorPag.vue";
import pagina from "@/class/pagina";

export default defineComponent({
  props: {
    id: {},
    pg: {
      default: 1,
    },
    escola_turm_p: 0,
    segmento_turm_p: 0,
  },
  components: {
    selectPorPag
  },
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "segmento", label: "Etapa", sortable: true },
      { key: "serie", label: "Período", sortable: true },
      { key: "escola", label: "Escola", sortable: true },
      { key: "ativa", label: "Estado" },
      /*{ key: "info", label: "Adicionar informações" },*/
      { key: "estado", label: "Ação" },
    ];

    return {
      startTurma_id_turma: 0,
      confime_startTurma: false,
      funcao: 0,
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Turmas" }],
      situacaoAno: 1,
      isloading: false,
      filter: "",
      turmasALL: [],
      escolaALL: [],
      segmentoALL: [],
      links: [],
      ano: null,
      columns,
      perPage: 50,
      currentPage: 1,
      pagina_atual: 1,
      pesquisa: null,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      escola_turm: 0,
      segmento_turm: 0,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.turmasALL.length / this.perPage)
        : this.turmasALL.length;
    },
  },

  async beforeMount() {
    await this.logado();
    await this.listarTudo();
    this.buscarEscolaALL();
    this.buscarSegmentoALL();
    this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));

    const acesso = await Verificar.AcessoLocal(9);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },

  methods: {
    changePagina(pg){
      pagina.routePg = "&pg="+pg
      pagina.pg = pg;
      this.listarTudo();
    },
    async limparFiltro() {
      this.pesquisa = '';
      this.escola_turm = 0;
      this.segmento_turm = 0;
      this.listarTudo();
    },
    async encerrarTurmaEja(dados) {
      if (window.confirm("Você irá encerrar esta turma, e os alunos estarão disponíveis para serem adicionados a outras turmas. Deseja continuar?")) {
        Turma.desativarTurma(dados.info.turma_id).then((resp) => {
              this.$vaToast.init({
                    message: "Turma encerrada com sucesso!",
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3000,
                    color: 'success',
                    fullWidth: false,
                });
          }).catch((err) => {
              this.$vaToast.init({
                  message: err.response.data,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3000,
                  color: 'danger',
                  fullWidth: false,
              });
            }).finally(() => {
              this.carregandoPagina = false;
            });
      }
      console.log(dados);
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    async pesquisar() {
      if (this.pesquisa != null && this.pesquisa != "" && this.pesquisa.length >= 3) {
        const data = await Turma.pesqTurmaCompleto(this.pesquisa, this.escola_turm, this.segmento_turm);
        this.links = [];
        this.turmasALL = [];

        for (const el of data.data) {
          const novo = {
            id: el.id,
            nome: el.nome,
            ativa: el.ativa,
            estado: {
              id: el.id,
              ativa: el.ativa,
            },
            escola: el.escola.nome,
            segmento_tipo: el.segmento.tipo,
            segmento: el.segmento.nome,
            serie: el.serie.nome,
            qdt_alunos: el.qdt_alunos,
            semestre: el.semestre,
            turno: el.turno,
            info: {
              id: el.id,
              ativa: el.ativa,
              turma_id: el.id,
              segmento_id: el.segmento_id,
              serie_id: el.serie_id,
              escola_id: el.escola_id,
              turno: el.turno,
            },
          };
          this.turmasALL.push(novo);
        }
        this.turmasALL.reverse();
      }
      else if (this.pesquisa != null && this.pesquisa != "" && this.pesquisa.length < 3) {
        this.$vaToast.init({
            message: "Digite pelo menos 3 caracteres para buscar os dados de turma!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "warning",
          });
      }
      else {
        this.listarTudo();
      }
    },
    async paginas(n) {
      if (n.url != null) {
        if (!n.active) {
          const anoSelect = sessionStorage.getItem("anoSelecionado");
          const numero = n.url.split("?page=");
          this.pagina_atual = numero[1];

          if (numero == 1) {
            let data;
            if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
              data = await Turma.obtemTodos(
                anoSelect,
                this.currentPage,
                this.escola_turm,
                this.segmento_turm
              );
            } else {
              const anoSelect = sessionStorage.getItem("anoSelecionado");
              const token = sessionStorage.getItem("token");
              const whoiam = await LoginInfo.WhoIam(token);

              const user = whoiam.data.usuario.id;
              const coordenacao = sessionStorage.getItem("coordenacao");
              data = await Turma.obtemTodosCoordenacao(
                user,
                coordenacao,
                anoSelect,
                this.currentPage,
                this.segmento_turm
              );
            }

            this.turmasALL = [];
            this.links = data.data.links;
            for (const el of data.data.data) {

              const novo = {
                id: el.id,
                nome: el.nome,
                ativa: el.ativa,
                estado: {
                  id: el.id,
                  ativa: el.ativa,
                },
                segmento_tipo: el.segmento.tipo,
                escola: el.escola.nome,
                segmento: el.segmento.nome,
                serie: el.serie.nome,
                qdt_alunos: el.qdt_alunos,
                semestre: el.semestre,
                turno: el.turno,
                info: {
                  id: el.id,
                  ativa: el.ativa,
                  turma_id: el.id,
                  segmento_id: el.segmento_id,
                  serie_id: el.serie_id,
                  escola_id: el.escola_id,
                  turno: el.turno,
                },
              };
              this.turmasALL.push(novo);
            }
            this.turmasALL.reverse();
          } else {
            this.currentPage = this.pagina_atual;
            if (
              (typeof n === "object" || typeof n === "function") &&
              n !== null
            ) {
              const routePg = pagina.routePg;
              const res = await axios.get(
                n.url +
                  "&escola=" +
                  this.escola_turm +
                  "&segmento=" +
                  this.segmento_turm
                  + routePg
              );
              this.turmasALL = [];
              //this.turmasALL = res.data.data;
              for (const el of res.data.data) {

                const novo = {
                  id: el.id,
                  nome: el.nome,
                  ativa: el.ativa,
                  estado: {
                    id: el.id,
                    ativa: el.ativa,
                  },
                  escola: el.escola.nome,
                  segmento: el.segmento.nome,
                  segmento_tipo: el.segmento.tipo,
                  serie: el.serie.nome,
                  qdt_alunos: el.qdt_alunos,
                  semestre: el.semestre,
                  turno: el.turno,
                  info: {
                    id: el.id,
                    ativa: el.ativa,
                    turma_id: el.id,
                    segmento_id: el.segmento_id,
                    serie_id: el.serie_id,
                    escola_id: el.escola_id,
                    turno: el.turno,
                  },
                };
                this.turmasALL.push(novo);
              }
              this.turmasALL.reverse();
              this.links = res.data.links;
            } else {
              const data = await Turma.obtemTodos(
                anoSelect,
                this.currentPage,
                this.escola_turm,
                this.segmento_turm
              );
              this.turmasALL = [];
              for (const el of data.data.data) {

                const novo = {
                  id: el.id,
                  nome: el.nome,
                  ativa: el.ativa,
                  estado: {
                    id: el.id,
                    ativa: el.ativa,
                  },
                  escola: el.escola.nome,
                  segmento: el.segmento.nome,
                  segmento_tipo: el.segmento.tipo,
                  serie: el.serie.nome,
                  qdt_alunos: el.qdt_alunos,
                  semestre: el.semestre,
                  turno: el.turno,
                  info: {
                    id: el.id,
                    ativa: el.ativa,
                    turma_id: el.id,
                    segmento_id: el.segmento_id,
                    serie_id: el.serie_id,
                    escola_id: el.escola_id,
                    turno: el.turno,
                  },
                };
                this.turmasALL.push(novo);
              }
              this.turmasALL.reverse();
              this.links = res.data.links;
            }
          }
        }
      }
    },
    async deletarTurma(id) {
      if (window.confirm("Você realmente deseja apagar a turma selecionada?")) {
        try {
          let dt;
          if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
            dt = await Turma.deletarTurmaAmbienteGestao(id);
          } else {
            dt = await Turma.deletarTurmaAmbienteCoordenacao(id);
          }

          this.turmasALL = [];
          this.listarTudo();
          this.$vaToast.init({
            message: "Turma apagada com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "success",
          });
        } catch (e) {
          alert("Turma Habilitada não pode ser Deletada!");
        }
      }
    },
    addDisciplina(info) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "turma-add-disciplina",
          params: {
            etapa_id: info.info.segmento_id,
            periodo_id: info.info.serie_id,
            turma_id: info.info.turma_id,
            escola_id: info.info.escola_id,
            turno: info.info.turno,
            tipoDeAcao: 1,
            pg: this.currentPage,
          },
        });
      } else {
        this.$router.push({
          name: "turma-add-disciplina-coordenacao",
          params: {
            etapa_id: info.info.segmento_id,
            periodo_id: info.info.serie_id,
            turma_id: info.info.turma_id,
            escola_id: info.info.escola_id,
            turno: info.info.turno,
            tipoDeAcao: 1,
            pg: this.currentPage,
          },
        });
      }
    },
    addDisciplinaEProfessor(info) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "turma-add-Professor",
          params: {
            etapa_id: info.info.segmento_id,
            periodo_id: info.info.serie_id,
            turma_id: info.info.turma_id,
            escola_id: info.info.escola_id,
            turno: info.info.turno,
            pg: this.currentPage,
          },
        });
      } else {
        this.$router.push({
          name: "turma-add-professor-coordenacao",
          params: {
            etapa_id: info.info.segmento_id,
            periodo_id: info.info.serie_id,
            turma_id: info.info.turma_id,
            escola_id: info.info.escola_id,
            turno: info.info.turno,
            pg: this.currentPage,
          },
        });
      }
    },
    addAlunosNaTurma(info) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "turma-add-aluno",
          params: {
            etapa_id: info.info.segmento_id,
            periodo_id: info.info.serie_id,
            turma_id: info.info.turma_id,
            escola_id: info.info.escola_id,
            turno: info.info.turno,
            tipoDeAcao: 2,
            ativa: info.info.ativa,
            pg: this.currentPage,
          },
        });
      } else {
        this.$router.push({
          name: "turma-add-aluno-coordenacao",
          params: {
            etapa_id: info.info.segmento_id,
            periodo_id: info.info.serie_id,
            turma_id: info.info.turma_id,
            escola_id: info.info.escola_id,
            turno: info.info.turno,
            tipoDeAcao: 2,
            ativa: info.info.ativa,
            pg: this.currentPage,
          },
        });
      }
    },

    async verificarPresenca(info) {
      const data = await Turma.obtemUm(info.id);
      this.$router.push({
        name: "turma-presencas-alunos",
        params: {
          escola_id: info.info.escola_id,
          segmento_id: data.data.segmento_id,
          periodo_id: data.data.serie_id,
          turma_id: data.data.id,
          pg: this.currentPage,
        },
      });
    },
    async verificarNota() {
      //let data = await Turma.obtemUm(info.id);
      this.$router.push({
        name: "turma-notas-alunos",
        params: {
          escola_id_st: 1,
          segmento_id_st: 1,
          periodo_id_st: 1,
          turma_id_st: 1,
          pg: this.currentPage,
        },
        /*params: {
          escola_id_st: data.data.escola_id,
          segmento_id_st: data.data.segmento_id,
          periodo_id_st: data.data.serie_id,
          turma_id_st: data.data.id,
          pg: this.currentPage,
        },*/
      });
    },
    editarTurma(id, turmaAtivada) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "turmasEditar",
          params: { id: id, turmaAtivada: turmaAtivada, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "coordenacao-turma-edit",
          params: { id: id, turmaAtivada: turmaAtivada, pg: this.currentPage },
        });
      }
    },
    registar() {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "turmasCadastrar" });
      } else {
        this.$router.push({ name: "coordenacao-turma-cadastrar" });
      }
    },
    visualizacaoTurma(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "visualizarturma",
          params: { id: id, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "visualizarturma-coordenacao",
          params: { id: id, pg: this.currentPage },
        });
      }
    },
    visualizacaoAlunos(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "alunosNaTurma",
          params: { id: id, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "alunosNaTurma",
          params: { id: id, pg: this.currentPage },
        });
      }
    },
    async start_turma(id_turma) {
      this.confime_startTurma = true;
      this.startTurma_id_turma = id_turma;
    },
    async start_turma_Confimado() {
      try {
        this.isloading = true;
        const id_turma = this.startTurma_id_turma;
        const turm = {
          turma_id: id_turma,
          ativa: 1,
          ano: this.ano,
        };
        this.confime_startTurma = false;
        const dt = await Turma.ativacãoTotalTurma(turm);
        this.listarTudo();
        this.isloading = false;
      } catch (e) {
        this.isloading = false;
        setTimeout(() => {
          this.$vaToast.init({
            message: e.response.data.erro,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "danger",
          });
        }, 500);
      }
    },
    async end_turma(id_turma) {
      this.isloading = true;
      /*ativar turmaa*/
      const payload = {
        turma_id: id_turma,
        ativa: 0,
      };
      const data4 = await Turma.ativacaoDaTurma(payload);
      this.listarTudo();
      this.isloading = false;
    },
    async listarTudo() {
      try {
        if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
          this.currentPage = this.pg;
          const anoSelect = sessionStorage.getItem("anoSelecionado");

          if (this.escola_turm_p !== undefined) {
            if (this.escola_turm_p != 0 && this.escola_turm == 0) {
              this.escola_turm = this.escola_turm_p;
            }
          }

          const data = await Turma.obtemTodos(
            anoSelect,
            this.currentPage,
            this.escola_turm,
            this.segmento_turm
          );

          this.turmasALL = [];
          this.links = data.data.links;
          for (const el of data.data.data) {

            const novo = {
              id: el.id,
              nome: el.nome,
              ativa: el.ativa,
              estado: {
                id: el.id,
                ativa: el.ativa,
              },
              segmento_tipo: el.segmento.tipo,
              escola: el.escola.nome,
              segmento: el.segmento.nome,
              serie: el.serie.nome,
              qdt_alunos: el.qdt_alunos,
              semestre: el.semestre,
              turno: el.turno,
              info: {
                id: el.id,
                ativa: el.ativa,
                turma_id: el.id,
                segmento_id: el.segmento_id,
                serie_id: el.serie_id,
                escola_id: el.escola_id,
                turno: el.turno,
              },
            };
            this.turmasALL.push(novo);
          }
          this.turmasALL.reverse();
        } else {
          this.currentPage = this.pg;
          const anoSelect = sessionStorage.getItem("anoSelecionado");
          const token = sessionStorage.getItem("token");
          const whoiam = await LoginInfo.WhoIam(token);

          const user = whoiam.data.usuario.id;
          const coordenacao = sessionStorage.getItem("coordenacao");

          const data = await Turma.obtemTodosCoordenacao(
            user,
            coordenacao,
            anoSelect,
            this.currentPage,
            this.segmento_turm
          );
          this.links = data.data.links;
          this.turmasALL = [];

          if (coordenacao == 1) {
            for (const el of data.data.data) {

              const novo = {
                id: el.id,
                nome: el.nome,
                ativa: el.ativa,
                estado: {
                  id: el.id,
                  ativa: el.ativa,
                },
                segmento_tipo: el.segmento.tipo,
                escola: el.escola.nome,
                segmento: el.segmento.nome,
                serie: el.serie.nome,
                qdt_alunos: el.qdt_alunos,
                semestre: el.semestre,
                turno: el.turno,
                info: {
                  id: el.id,
                  ativa: el.ativa,
                  turma_id: el.id,
                  segmento_id: el.segmento_id,
                  serie_id: el.serie_id,
                  escola_id: el.escola_id,
                  turno: el.turno,
                },
              };
              this.turmasALL.push(novo);
            }
          } else if (coordenacao == 2) {
            for (const el of data.data.data) {
              const novo = {
                id: el.id,
                nome: el.nome,
                ativa: el.ativa,
                estado: {
                  id: el.id,
                  ativa: el.ativa,
                },
                segmento_tipo: el.segmento.tipo,
                escola: el.escola.nome,
                segmento: el.segmento.nome,
                serie: el.serie.nome,
                qdt_alunos: el.qdt_alunos,
                semestre: el.semestre,
                turno: el.turno,
                info: {
                  id: el.id,
                  ativa: el.ativa,
                  turma_id: el.id,
                  segmento_id: el.segmento_id,
                  serie_id: el.serie_id,
                  escola_id: el.escola_id,
                  turno: el.turno,
                },
              };
              this.turmasALL.push(novo);
            }
          }
        }
      } catch (e) {}
    },
    async buscarEscolaALL() {
      const data = await Escola.obtemTodos();
      this.escolaALL = data.data;
    },
    async buscarSegmentoALL() {
      if(this.escola_turm){
        this.segmentoALL = []
        const data = await SegmentoEscolar.obtemSegmentosEscola(this.escola_turm);
        this.segmentoALL = data.data;
      }else{
        this.segmentoALL = []
      }
      // const data = await SegmentoEscolar.obtemTodos();
      // this.segmentoALL = data.data;
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.colortuma1 {
  background-color: #20d1a0 !important;
  border-color: #20d1a0 !important;
}
.colortuma1:hover {
  background-color: #20d1a0 !important;
}

.badge-noturno {
  color: #fff;
  background-color: #2b22b5;
  font-size: 11px;
  font-weight: 500 !important;
}
.badge-vespertino {
  color: #fff;
  background-color: #efa029;
  font-size: 11px;
  font-weight: 500 !important;
}
.badge-matutino {
  color: #fff;
  background-color: #28a0ff;
  font-size: 11px;
  font-weight: 500 !important;
}
</style>
